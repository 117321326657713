<template>
  <v-card elevation="0">
    <v-card-title>Labels</v-card-title>
  </v-card>

  <v-list id="labels-list">
    <!-- FIXME: key -->
    <v-list-item v-for="(label, index) in labels" :key="index">
      <v-text-field v-model.trim="labels[index]" @focus="focus_index = index" :readonly="false" required :autofocus="(mobile ? false : true) || focus_index === index" variant="solo" autocapitalize="none">
        <template #prepend>
          <v-icon v-if="focus_index === index" @click="delete_dialog = true; delete_index = index">mdi-delete</v-icon>
          <v-icon v-else @click="focus_index = index">mdi-label</v-icon>
        </template>

        <template #append>
          <v-icon v-if="focus_index === index" @click="label_save(index)">mdi-check</v-icon>
          <v-icon v-else @click="focus_index = index">mdi-pencil</v-icon>
        </template>
      </v-text-field>
    </v-list-item>
  </v-list>

  <v-dialog v-model="delete_dialog" :width="mobile ? '' : '500'">
    <v-card>
      <v-card-title>
        Delete
      </v-card-title>

      <v-card-text>
        Are you sure you want to delete this label? The action cannot be undone. Your words won't be deleted.
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="delete_dialog = false">Cancel</v-btn>
        <v-btn color="primary" @click="label_delete(index)">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-col v-if="new_labels.length === 0" id="labels-commands">
    <v-btn id="label-add" icon color="primary">
      <v-icon @click="new_labels.push(''); focus_index = store.labels.length">mdi-plus</v-icon>
    </v-btn>
  </v-col>
  <v-col v-else-if="focus_index !== null" id="labels-commands">
    <v-btn icon color="primary">
      <v-icon @click="focus_index = null">mdi-close</v-icon>
    </v-btn>
  </v-col>
</template>

<script setup>
import { useDisplay } from 'vuetify'
import { appStore } from '@/store'
import { computed } from 'vue'
import { ref } from 'vue'

const { mobile } = useDisplay()
const store = appStore()

const delete_dialog = ref(false)
const delete_index = ref(null)
const focus_index = ref(null)

const labels = computed(() => { return [...store.labels, ...new_labels.value] })
const new_labels = ref([])

async function label_save(index) {
  let text = labels.value[index]

  if (index >= store.labels.length) {
    try {
      await store.lengualoo_api.post('/api/labels/', { text: labels.value[index] })
    } catch (e) {
      console.log(e)
      return
    }

    store.labels.push(text)
    new_labels.value.splice(0)
  } else {
    try {
      await store.lengualoo_api.put(`/api/labels/${ store.labels[index] }/`, { text: text })
    } catch (e) {
      console.log(e)
      return
    }

    store.labels[index] = text
  }

  focus_index.value = null
}

async function label_delete() {
  if (delete_index.value >= store.labels.length) {
    new_labels.value.splice(0)
  } else {
    try {
      await store.lengualoo_api.delete(`/api/labels/${ store.labels[delete_index.value] }/`)
    } catch (e) {
      console.log(e)
      return
    }

    store.labels.splice(delete_index.value, 1)
  }

  delete_index.value = null
  delete_dialog.value = false
  focus_index.value = null
}
</script>

<style scoped>
h1 {
  margin: 0 16px;
}

#labels-commands {
  width: auto;
  text-align: right;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 100;
}
</style>
