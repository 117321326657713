<template>
  <div class="words" style="position: relative;">
    <v-progress-linear v-if="isLoading" indeterminate color="primary" absolute/>

    <v-card elevation="0">
      <v-card-title>
        {{ $t('routes.Words') }}
        <v-chip v-if="language != undefined && language" class="ml-2">{{ all_languages[language]['name'] }}</v-chip>
        <v-chip v-if="n_words !== null" class="ml-2">{{ n_words }}</v-chip>
      </v-card-title>
    </v-card>

    <v-card v-if="error" elevation="0">
      <v-card-subtitle>An error occurred: {{ error }}</v-card-subtitle>
    </v-card>

    <div class="words-list" v-else>
      <v-list id="words-list">
        <div v-for="(word, index) in words" :key="word.uuid" v-touch:hold="onWordHold(word.uuid)" v-touch:tap="onWordTap(word.uuid)" :class="selected_words.includes(word.uuid) ? 'bg-grey-darken-2' : ''">
          <SwipeItem :id="`word-${ word.uuid }`" :uuid="word.uuid"
                     :title="getWordTitle(word)"
                     :subtitle="store.show_definitions ? (word.definitions.map(d => d.text).join(', ') || '...') : ''"
                     :append="language ? '' : word.language"
                     :labels="word.labels"
                     :part-of-speech="parts_of_speech[word.part_of_speech]"
                     :swipe-icon-left="swipe_icon_left" :swipe-icon-right="swipe_icon_right"
                     @action="wordAction"
          />
          <word-dialog title="Edit Word" :word="word" @fetch-words="fetchWords(bucket)" :words="all_words" activator="parent"/>
          <v-card v-if="index === words.length - 10" v-intersect="infiniteScrolling"/>
        </div>
      </v-list>
    </div>
  </div>

  <v-bottom-navigation app hide-on-scroll scroll-threshold="1" scroll-target="#words-list">
    <!--
    <v-btn value="favorites" @click="fetchWords('favourites')"><v-icon>mdi-heart</v-icon>Favorites</v-btn>
    -->
    <v-btn @click="fetchWords('unknown')" :active="bucket == 'unknown'"><v-icon>mdi-head-dots-horizontal</v-icon>Unkown</v-btn>
    <v-btn @click="fetchWords('known')" :active="bucket == 'known'"><v-icon>mdi-lightbulb-on</v-icon>Known</v-btn>
    <v-btn @click="fetchWords('spam')" :active="bucket == 'spam'"><v-icon>mdi-alert-octagon-outline</v-icon>Spam</v-btn>
  </v-bottom-navigation>

  <word-import ref="import_words" @fetch-words="fetchWords('unknown')"/>
  <word-dialog ref="add_word" title="Add Word" :word="{ definitions: null }" @fetch-words="fetchWords('unknown')" :words="all_words"/>
</template>

<script setup>
import { computed } from 'vue'
import { ref } from 'vue'
import { onMounted } from 'vue'
import { useVibrate } from '@vueuse/core'

import { appStore } from '@/store'
import { all_languages } from '@/store'
import { parts_of_speech } from '@/store'

import SwipeItem from '../components/SwipeItem.vue'
import WordDialog from '../components/WordDialog.vue'
import WordImport from '../components/WordImport.vue'

const store = appStore()

const bucket_icons = {
  'unknown': 'mdi-head-dots-horizontal',
  'known': 'mdi-lightbulb-on',
  'spam': 'mdi-alert-octagon-outline',
}

const bucket_actions = {
  'unknown': {
    'left': 'spam',
    'right': 'known',
  },
  'known': {
    'left': 'unknown',
    'right': 'spam',
  },
  'spam': {
    'left': 'known',
    'right': 'unknown',
  },
}

const language = ref(store.language)
const remaining_words = ref([])
const n_words = ref(null)
const words = ref([])
const selected_words = ref([])
const bucket = ref('')
const swipe_icon_left = ref('')
const swipe_icon_right = ref('')

const isLoading = ref(true)
const error = ref(null)
const add_word = ref(null)
const import_words = ref(null)

const sorting = ref('created')
const search = ref(null)
const align = ref(0)
const direction = ref(0)

const filters_parts_of_speech = ref(store.filters_parts_of_speech)
const filters_label = ref(store.filters_label)

const with_definitions = ref(true)

const all_words = computed(() => {
  return [...words.value, ...remaining_words.value]
})

function getWordTitle(word) {
  let text = word.text

  if (word.inflects && word.inflects.text) {
    text = `${text} ← ${word.inflects.text}`
  }

  if (word.inflections.length) {
    text = `${text} ⇒ ${word.inflections.map(i => i.text).join(', ')}` // → ↪
  }

  return text
}

store.$subscribe((mutation, state) => {
  if (sorting.value != state.sorting) {
    sorting.value = state.sorting
    fetchWords(bucket.value)
  }

  if (search.value != state.search) {
    search.value = state.search
    fetchWords(bucket.value)
  }

  if (align.value != state.align) {
    align.value = state.align
    fetchWords(bucket.value)
  }

  if (direction.value != state.direction) {
    direction.value = state.direction
    fetchWords(bucket.value)
  }

  if (language.value != state.language) {
    language.value = state.language
    fetchWords(bucket.value)
  }

  if (JSON.stringify(filters_parts_of_speech.value.sort()) !== JSON.stringify(state.filters_parts_of_speech.sort())) {
    filters_parts_of_speech.value = state.filters_parts_of_speech.slice(0)
    fetchWords(bucket.value)
  }

  if (filters_label.value != state.filters_label) {
    filters_label.value = state.filters_label
    fetchWords(bucket.value)
  }

  if (with_definitions.value != state.with_definitions) {
    with_definitions.value = state.with_definitions
    fetchWords(bucket.value)
  }
})

function onWordHold(uuid) {
  return function () {
    if (selected_words.value.includes(uuid)) {
      selected_words.value.splice(selected_words.value.indexOf(uuid), 1)
    } else {
      selected_words.value.push(uuid)
      const { vibrate } = useVibrate({ pattern: [200] })
      vibrate()
    }
  }
}

function onWordTap(uuid) {
  return function (ev) {
    if (!selected_words.value.length) {
      return
    }

    if (selected_words.value.includes(uuid)) {
      selected_words.value.splice(selected_words.value.indexOf(uuid), 1)
    } else {
      selected_words.value.push(uuid)
    }

    ev.preventDefault()
  }
}

onMounted(() => {
  fetchWords('unknown')
})

function wordAction(uuid, action) {
  var _bucket = bucket_actions[bucket.value][action]  // FIXME
  if (!_bucket)
    return

  words.value.forEach(function(word, index) {
    if(word['uuid'] === uuid) {
      words.value.splice(index, 1)
      return
    }
  })

  if (selected_words.value.includes(uuid)) {
    selected_words.value.splice(selected_words.value.indexOf(uuid), 1)
  }

  if (moveWord(uuid, _bucket)) {
    n_words.value -= 1
  }
}

async function moveWord(uuid, action) {
  try {
    await store.lengualoo_api.post(`/api/words/${ uuid }/move/`, { bucket: action })

    return true
  } catch (e) {
    console.log('Error:', e)
  }

  return false
}

async function fetchWords(where) {
  if (bucket.value != where) {
    n_words.value = null
    words.value.splice(0, words.value.length)

    isLoading.value = true
  }

  bucket.value = where
  swipe_icon_left.value = bucket_icons[bucket_actions[bucket.value]['right']]
  swipe_icon_right.value = bucket_icons[bucket_actions[bucket.value]['left']]

  if (language.value === undefined) {
    words.value = []
    return
  }

  try {
    const response = await store.lengualoo_api.get('/api/words/', {
      params: {
        bucket: bucket.value,
        sorting: store.sorting,
        search: store.search,
        align: store.aligns[store.align]['align'],
        direction: store.directions[store.direction]['direction'],
        language: store.language,
        parts_of_speech: store.filters_parts_of_speech,
        label: store.filters_label,
        with_definitions: store.with_definitions ? true : '',
      },
    })

    remaining_words.value.splice(0)
    remaining_words.value.push(...response.data)
    words.value.splice(0)
    selected_words.value.splice(0)

    n_words.value = remaining_words.value.length

    pushWords()

    error.value = null
  } catch (e) {
    error.value = e.message
  } finally {
    isLoading.value = false
  }
}

function pushWords() {
  let n_words = 30

  words.value.push(...remaining_words.value.splice(0, n_words))
}

function infiniteScrolling(intersecting) {
  if (intersecting && remaining_words.value.length) {
    pushWords()
  }
}

defineExpose({
  add_word,
  import_words,
  selected_words,
  remaining_words,
  words,
  all_words,
  fetchWords,
  bucket,
  isLoading,
})
</script>

<style> /* FIXME: hack to style the SwipeItem elements */
#words-list {
  overflow-x: hidden;
}
</style>

<style scoped>
h1 {
  margin: 0 16px;
}

#words-list > div {
  border-radius: 10px;
  margin: 2px 5px;
}
</style>
