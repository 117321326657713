<template>
  <v-card elevation="0">
    <v-card-title>Sources</v-card-title>
  </v-card>

  <div class="sources">
    <v-card v-if="isLoading" elevation="0">
      <v-card-subtitle>Loading...</v-card-subtitle>
    </v-card>

    <v-card v-else-if="error" elevation="0">
      <v-card-subtitle>An error occurred: {{ error }}</v-card-subtitle>
    </v-card>

    <div class="sources-list" v-else>
      <v-list id="sources-list">
        <v-list-item v-for="(source, index) in sources" :key="index">
          {{ source.name }}

          <div style="padding-left: 50px">
            {{ source.words }}
          </div>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { onMounted } from 'vue'

import { appStore } from '@/store'

const store = appStore()

const sources = ref([])

const isLoading = ref(true)
const error = ref(null)

onMounted(() => {
  fetchSources()
})

async function fetchSources() {
  isLoading.value = true
  sources.value = []
  //if (!error.value) return

  /*
  if (language.value === undefined) {
    definitions.value = []
    return
  }
  */

  try {
    const response = await store.lengualoo_api.get('/api/sources/', {
      params: {
        language: store.language,
      },
    })

    sources.value = response.data
    window.sources = sources.value

    error.value = null
  } catch (e) {
    error.value = e.message
  } finally {
    isLoading.value = false
  }
}


</script>
