<template>
  <swiper :id="id" slides-per-view="auto" :initial-slide="1" :resistance-ratio="0" :speed="150" :centered-slides="true" :centered-slides-bounds="true" @touch-end="onTouchEnd">
    <swiper-slide class="left">
      <v-list-item><v-icon>{{ swipeIconLeft }}</v-icon></v-list-item>
    </swiper-slide>
    <swiper-slide class="center">
      <v-list-item link :title="title" :subtitle="subtitle">
        <template v-if="append || labels || partOfSpeech" #append>
          <v-chip v-if="labels.length" rounded="true" class="justify-center">
            <v-icon start icon="mdi-label" color="grey-darken-2"/>
            {{ labels.length }}
          </v-chip>
          <v-chip v-if="partOfSpeech" rounded="true" class="justify-center">
            {{ partOfSpeech }}
          </v-chip>
          <v-chip v-if="append" rounded="true" style="width: 0.6em;" class="justify-center">{{ append }}</v-chip>
        </template>
      </v-list-item>
    </swiper-slide>
    <swiper-slide class="right">
      <v-list-item><v-icon>{{ swipeIconRight }}</v-icon></v-list-item>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

const props = defineProps({
  id: { type: String, default: '' },
  uuid: { type: String, default: '' },
  title: { type: String, default: '' },
  subtitle: { type: String, default: '' },
  swipeIconLeft: { type: String, default: '' },
  swipeIconRight: { type: String, default: '' },
  append: { type: String, default: '' },
  labels: { type: Array, default: () => [] },
  partOfSpeech: { type: String, default: '' },
})

const emit = defineEmits(['action'])

function onTouchEnd(swiper) {
  if (swiper.progress === 0) {
    swiper.slideTo(1, 10, false)

    emit('action', props.uuid, 'right')
  } else if (swiper.progress == 1) {
    swiper.slideTo(1, 10, false)

    emit('action', props.uuid, 'left')
  } else {
    swiper.slideTo(1, 10, false)
  }
}
</script>

<style scoped>
  .left, .right {
    display: flex;
    width: auto;
  }

  .swiper {
    user-select: none;
  }
</style>
