<template>
  <v-card elevation="0">
    <v-card-title>Login</v-card-title>
    <v-card-text>Lengualoo is an application to help you learn words by yourself.</v-card-text>
  </v-card>

  <v-card class="pa-4" elevation="0">
    <v-text-field v-model.trim="username" variant="solo" required :autofocus="mobile ? false : true" label="Username" autocapitalize="none"/>
    <v-text-field v-model.trim="password" variant="solo" required label="Password" type="password"/>
  </v-card>

  <v-card class="text-center pb-8" elevation="0">
    <v-btn color="primary" large text @click="store.login(username, password)">Sign in</v-btn>
  </v-card>
</template>

<script setup>
import { ref } from 'vue'
import { useDisplay } from 'vuetify'

import { appStore } from '@/store'

const store = appStore()
const { mobile } = useDisplay()

const username = ref('')
const password = ref('')
</script>
