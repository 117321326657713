<template>
  <v-card elevation="0">
    <v-card-title>Profile</v-card-title>
  </v-card>

  <v-card class="pa-4" elevation="0">
    <v-card-subtitle class="pl-0 pb-2 mandatory" :class="profile.target_language ? '' : 'missing'">The main language you want to learn *</v-card-subtitle>
    <v-autocomplete v-model="profile.target_language" variant="solo" :items="store.all_languages" item-title="name" item-value="id" hide-details="true" required/>

    <v-card-subtitle class="pl-0 pb-2 pt-4">Additional languages you want to learn</v-card-subtitle>
    <v-autocomplete v-model="profile.additional_languages" variant="solo" multiple :items="store.all_languages.slice(1)" item-title="name" item-value="id" hide-details="true"/>

    <v-card-subtitle class="pl-0 pb-2 pt-4 mandatory" :class="profile.target_translation ? '' : 'missing'">The main language you will use to learn *</v-card-subtitle>
    <v-autocomplete v-model="profile.target_translation" variant="solo" :items="store.all_languages" item-title="name" item-value="id" hide-details="true" required/>

    <v-card-subtitle class="pl-0 pb-2 pt-4">Additional languages you will use to learn</v-card-subtitle>
    <v-autocomplete v-model="profile.additional_translations" variant="solo" multiple :items="store.all_languages.slice(1)" item-title="name" item-value="id" hide-details="true"/>
  </v-card>

  <v-card class="text-center pb-8" elevation="0">
    <v-btn color="primary" large text @click="profile_save()">Save</v-btn>
  </v-card>
</template>

<script setup>
import { onMounted } from 'vue'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

import { appStore } from '@/store'

const router = useRouter()
const store = appStore()

const profile = reactive({
  target_language: '',
  target_translation: '',
  additional_languages: [],
  additional_translations: [],
})

async function profile_save() {
  try {
    await store.lengualoo_api.put('/api/profile/', {
      profile: {
        target_language: profile.target_language,
        target_translation: profile.target_translation,
        additional_languages: profile.additional_languages,
        additional_translations: profile.additional_translations,
      },
    })
    await store.loadProfile()

    router.push({ name: 'words' })
  } catch(error) {
    console.log('ERROR', error)
  }
}

onMounted(async () => {
  await store.loadProfile()

  profile.target_language = store.getProfile['target_language']
  profile.target_translation = store.getProfile['target_translation']
  profile.additional_languages = store.getProfile['additional_languages']
  profile.additional_translations = store.getProfile['additional_translations']
})
</script>

<style scoped>
.mandatory.missing {
  color: rgb(var(--v-theme-primary));
}
</style>
