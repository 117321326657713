import axios from 'axios'
import { defineStore } from 'pinia'
import { Capacitor } from '@capacitor/core'

import router from '@/router'

export const all_languages = {
  '': { name: '', short_name: '' },
  'eu': { name: 'Basque', short_name: 'EU' },
  'ca': { name: 'Catalan', short_name: 'CA' },
  'en': { name: 'English', short_name: 'EN' },
  'fr': { name: 'French', short_name: 'FR' },
  'de': { name: 'German', short_name: 'DE' },
  'el': { name: 'Greek', short_name: 'EL' },
  'it': { name: 'Italian', short_name: 'IT' },
  'la': { name: 'Latin', short_name: 'LA' },
  'pt': { name: 'Portuguese', short_name: 'PT' },
  'es': { name: 'Spanish', short_name: 'ES' },
  'tr': { name: 'Turkish', short_name: 'TR' },
}

export const parts_of_speech = {
  '': '',
  'NOUN': 'Noun',
  'PRONOUN': 'Pronoun',
  'ADJECTIVE': 'Adjective',
  'VERB': 'Verb',
  'ADVERB': 'Adverb',
  'PREPOSITION': 'Preposition',
  'CONJUNCTION': 'Conjunction',
  'ARTICLE': 'Article',
  'DETERMINER': 'Determiner',
  'PARTICLE': 'Particle',
  'INTERJECTION': 'Interjection',
  'NUMERAL': 'Numeral',
  'PROPER_NOUN': 'Proper Noun',
  'PHRASE': 'Phrase',
}

export const appStore = defineStore('app-store', {
  state: () => {
    return {
      is_authenticated: JSON.parse(localStorage.getItem('is_authenticated')),
      lengualoo_api: axios.create({
        baseURL: process.env.VUE_APP_API_BASE_URL || `${window.location.protocol}//${window.location.hostname}:${window.location.port}`.replace(/:$/, ''),
        xsrfHeaderName: 'X-CSRFTOKEN',
        xsrfCookieName: 'csrftoken',
        withCredentials: true,
        withXSRFToken: true,
        headers: Capacitor.isNativePlatform() ? {
          Referer: 'https://lengualoo.com',
        } : {},
      }),
      profile: {},

      aligns: {
        0: { align: 'middle', icon: 'mdi-format-align-center' },
        1: { align: 'start', icon: 'mdi-format-align-left' },
        2: { align: 'end', icon: 'mdi-format-align-right' },
      },
      directions: {
        0: { direction: 'default', icon: 'mdi-sort-ascending' },
        1: { direction: 'reverse', icon: 'mdi-sort-descending' },
      },

      search: null,
      with_definitions: true,
      show_definitions: true,
      show_words: true,
      sorting: 'created',
      align: 0,
      direction: 0,
      filters_parts_of_speech: [],
      filters_label: null,

      parts_of_speech: Object.keys(parts_of_speech).map(x => ({ value: x, title: (x ? parts_of_speech[x] : 'Undefined') })),

      all_languages: Object.keys(all_languages).map(x => ({ id: x, ...all_languages[x] })),
      language: undefined,
      translation: undefined,
      activated_languages: [],
      activated_translations: [],

      labels: [],
    }
  },

  getters: {
    isAuthenticated(state) {
      return state.is_authenticated
    },

    getProfile(state) {
      return state.profile
    },
  },

  actions: {
    async checkAuthentication() {
      let response = {}

      try {
        response = await this.lengualoo_api.get('/api/is-authenticated/')
      } catch (e) {
        if (e.response && e.response['status'] === 403) {
          this.is_authenticated = false
        } else {
          console.log(`Error while checking authentication: ${ e }`)
        }
      }

      if (response.status === 200) {
        this.is_authenticated = true
      }

      localStorage.setItem('is_authenticated', this.is_authenticated)

      return this.is_authenticated
    },

    async login(username, password) {
      try {
        const response = await this.lengualoo_api.post('/api/login/', {
          username: username,
          password: password,
        })
        if (response.status === 200) {
          await this.checkAuthentication()
          await this.loadProfile()
          await this.loadLabels()

          var route = 'words'
          const from = router.currentRoute.value.redirectedFrom
          if (from && from.name) {
            route = from.name
          }

          router.push({ name: route })
        }
      } catch (error) {
        console.log('ERROR', error)
      }
    },

    async logout() {
      try {
        await this.lengualoo_api.post('/api/logout/')
        await this.checkAuthentication()

        router.push({ name: 'home' })
      } catch (error) {
        console.log('ERROR', error)
      }
    },

    async loadProfile() {
      console.log('Loading Profile')

      try {
        const response = await this.lengualoo_api.get('/api/profile/')
        if (response.status === 200) {
          this.profile = response.data

          this.language = this.profile.target_language || ''
          this.translation = this.profile.target_translation || ''

          this.activated_languages.splice(0)
          if (this.language) {
            this.activated_languages.push({ id: this.language, ...all_languages[this.language] })
          }
          this.activated_languages.push(...this.profile.additional_languages.map(x => ({ id: x, ...all_languages[x] })))

          this.activated_translations.splice(0)
          if (this.translation) {
            this.activated_translations.push({ id: this.translation, ...all_languages[this.translation] })
          }
          this.activated_translations.push(...this.profile.additional_translations.map(x => ({ id: x, ...all_languages[x] })))
        }
      } catch (error) {
        console.log('ERROR', error)
      }
    },

    async loadLabels() {
      console.log('Loading Labels')

      try {
        const response = await this.lengualoo_api.get('/api/labels/')
        this.labels = response.data.map(l => l.text)
      } catch (error) {
        console.log('ERROR', error)
      }
    },
  },
})
