<template>
  <v-dialog v-model="dialog" :fullscreen="mobile" :hide-overlay="mobile" :width="mobile ? '' : '500'">
    <v-card density="compact">
      <v-toolbar color="primary">
        <v-btn @click="dialog = false" icon="mdi-arrow-left" density="compact"/>
        <v-toolbar-title>Import Words</v-toolbar-title>
        <v-spacer/>
        <v-btn v-if="data.new_words.length && !loading" @click="import_words" icon="mdi-check"/>
        <v-btn v-if="!data.new_words.length && (((from == 'snippet') && snippet) || ((from == 'url') && url) || ((from == 'file') && files.length)) && !loading" @click="find_words" icon="mdi-magnify"/>
        <v-btn v-if="loading" class="pr-0">
          <v-progress-circular width="2" size="24" indeterminate/>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col>
            <v-card-subtitle class="pb-2 pl-0">From</v-card-subtitle>
            <v-select append-inner-icon="" v-model="from" variant="solo" :items="froms" item-title="name" item-value="id"/>
          </v-col>
        </v-row>

        <template v-if="from == 'snippet'">
          <v-row>
            <v-col>
              <v-card-subtitle class="pb-2 pl-0">Text</v-card-subtitle>
              <v-textarea prepend-inner-icon="mdi-text-box-outline" v-model.trim="snippet" required :autofocus="mobile ? false : true" variant="solo" autocapitalize="none"/>
            </v-col>
          </v-row>
        </template>

        <template v-if="from == 'url'">
          <v-row>
            <v-col>
              <v-card-subtitle class="pb-2 pl-0">URL</v-card-subtitle>
              <v-text-field prepend-inner-icon="mdi-web" v-model.trim="url" required :autofocus="mobile ? false : true" variant="solo" type="url" autocapitalize="none"/>
            </v-col>
          </v-row>
        </template>

        <template v-if="from == 'file'">
          <v-row>
            <v-col>
              <v-card-subtitle class="pb-2 pl-0">Image</v-card-subtitle>
              <v-file-input v-model="files"
                            :loading="false"
                            required
                            :autofocus="mobile ? false : true"
                            variant="solo"
                            accept="image/*"
                            show-size
                            prepend-icon="mdi-paperclip"
                            append-icon="mdi-camera"
                            @click:prepend="pickImages"
                            @click:append="takePhoto"
              />
            </v-col>
          </v-row>
        </template>

        <v-row v-if="!store.language">
          <v-col>
            <v-card-subtitle class="pb-2 pl-0">Language</v-card-subtitle>
            <v-autocomplete append-inner-icon="" v-model="language" variant="solo" :items="store.activated_languages" item-title="name" item-value="id"/>
          </v-col>
        </v-row>

        <v-tabs v-model="tab" v-if="data.new_words.length || data.unknown_words.length || data.known_words.length">
          <v-tab value="new_words" v-if="data.new_words.length">New<v-chip class="ml-2">{{ data.new_words.length }}</v-chip></v-tab>
          <v-tab value="unknown_words" v-if="data.unknown_words.length">Unlearned<v-chip class="ml-2">{{ data.unknown_words.length }}</v-chip></v-tab>
        </v-tabs>

        <v-list v-if="tab == 'new_words'">
          <v-list-item v-if="data.new_words.length">
            <v-select v-model="skip_pos" label="Skip" variant="solo" multiple :items="store.parts_of_speech">
              <template #selection="{ item, index }">
                <v-chip v-if="index < 2">{{ item.title }}</v-chip>
                <span v-if="index === 2" class="text-grey text-caption align-self-center">
                  (+{{ skip_pos.length - 2 }} more)
                </span>
              </template>
            </v-select>
          </v-list-item>

          <v-list-item v-if="data.new_words.length">
            <v-checkbox v-model="new_words_selected" :indeterminate="new_words_selected === null" density="compact" hide-details="auto" @click="new_words_toggle">
              <template #label>
                <v-chip>
                  Import {{ data.new_words.filter(x => x.save).length }}
                </v-chip>
                <v-chip>
                  Skip {{ data.new_words.filter(x => !x.save).length }}
                </v-chip>
              </template>
            </v-checkbox>
          </v-list-item>

          <!-- FIXME: replace key -->
          <v-list-item v-for="word in data.new_words.filter(x => !skip_pos.includes(x.tag))" :key="word">
            <v-checkbox v-model="word.save" density="compact" hide-details="auto">
              <template #label>
                {{ word.lemma }}
              </template>
            </v-checkbox>

            <template v-if="word.tag" #append>
              <v-chip rounded="true" class="justify-center">
                {{ parts_of_speech[word.tag] }}
              </v-chip>
            </template>
          </v-list-item>
        </v-list>

        <v-list v-if="tab == 'unknown_words'">
          <!-- FIXME: replace key -->
          <v-list-item v-for="word in data.unknown_words" :key="word">
            <template #title>
              {{ word.lemma }}
            </template>

            <template v-if="word.tag" #append>
              <v-chip rounded="true" class="justify-center">
                {{ parts_of_speech[word.tag] }}
              </v-chip>
            </template>

            <template #subtitle>
              {{ word.defs.join(', ') || '...' }}
            </template>
          </v-list-item>
        </v-list>

        <v-list v-if="tab == 'known_words'">
          <!-- FIXME: replace key -->
          <v-list-item v-for="word in data.known_words" :key="word">
            <template #title>
              {{ word.lemma }}
            </template>

            <template v-if="word.tag" #append>
              <v-chip rounded="true" class="justify-center">
                {{ parts_of_speech[word.tag] }}
              </v-chip>
            </template>

            <template #subtitle>
              {{ word.defs.join(', ') || '...' }}
            </template>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { Capacitor } from '@capacitor/core'
import { Filesystem } from '@capacitor/filesystem'
import { FilePicker } from '@capawesome/capacitor-file-picker'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'

import { computed } from 'vue'
import { onMounted } from 'vue'
import { reactive } from 'vue'
import { ref } from 'vue'
import { watch } from 'vue'
import { useDisplay } from 'vuetify'

import { appStore } from '@/store'
import { parts_of_speech } from '@/store'

const store = appStore()
const { mobile } = useDisplay()

const emit = defineEmits(['fetchWords'])

const froms = [
  { id: 'snippet', name: 'Snippet' },
  { id: 'url', name: 'Website' },
  { id: 'file', name: 'File' },
]

const dialog = ref(false)
const loading = ref(false)

const language = ref('')
const snippet = ref('')
const url = ref('')
const files = ref([])

const data = reactive({
  new_words: [],
  unknown_words: [],
  known_words: [],
})

const from = ref('snippet')
const tab = ref('')
const skip_pos = ref(['PROPER_NOUN'])

store.$subscribe((mutation, state) => {
  language.value = state.language || store.profile.target_language
})

onMounted(() => {
  language.value = store.language || store.profile.target_language
})

watch([dialog, from], ([dialog, from]) => {
  if (!dialog) {
    document.title = 'Lengualoo'

    data.new_words = []
    data.unknown_words = []
    data.known_words = []
  } else {
    document.title = 'dialog'
  }

  if (from) {
    data.new_words = []
    data.unknown_words = []
    data.known_words = []
  }
})

watch(skip_pos, (skip_pos, old_skip_pos) => {
  let add_skip_pos = skip_pos.filter(x => !old_skip_pos.includes(x))

  data.new_words.filter(x => add_skip_pos.includes(x.tag)).forEach(x => x.save = false)
})

const new_words_selected = computed(() => {
  if (data.new_words.filter(x => !skip_pos.value.includes(x.tag)).every(x => x.save)) {
    return true
  }

  if (data.new_words.every(x => !x.save)) {
    return false
  }

  return null
})

async function requestPermissions() {
  try {
    const permissions = await Filesystem.requestPermissions()
    console.log('Permissions granted: ', permissions)
  } catch (error) {
    console.error('Error requesting permissions: ', error)
  }
}

function base64ToBlob(base64, contentType) {
  const byteCharacters = atob(base64)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

const pickImages = async () => {
  if (!Capacitor.isNativePlatform()) {
    return
  }

  await requestPermissions()

  let result

  try {
    result = await FilePicker.pickFiles()
  } catch (error) {
    if (error.message === 'pickFiles canceled.') {
      console.log('File selection was canceled')
    } else {
      console.error('An error occurred:', error)
    }

    return
  }

  const file = result.files[0]

  const readFileOptions = {
    path: file.path,
  }

  const fileData = await Filesystem.readFile(readFileOptions)
  const base64Data = fileData.data
  const blob = base64ToBlob(base64Data, file.mimeType)

  files.value = [blob]
}

async function takePhoto() {
  try {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 90,
      allowEditing: true,
    })

    const response = await fetch(photo.webPath)
    const blob = await response.blob()

    files.value = [blob]
  } catch (error) {
    console.error('Camera error:', error.message)
  }
}

function new_words_toggle() {
  if (new_words_selected.value) {
    data.new_words.forEach(e => e.save = false)
  } else {
    data.new_words.forEach(e => e.save = skip_pos.value.includes(e.tag) ? false : true)
  }
}

const readFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      const uarray = new Uint8Array(reader.result)
      const blob = new Blob([uarray], { type: 'application/octet-stream' })

      resolve(blob)
    }
    reader.onerror = error => reject(error)
  })
}

async function find_words() {
  loading.value = true

  let response = null

  if (from.value === 'snippet') {
    response = await ingest_snippet()
  } else if (from.value === 'url') {
    response = await ingest_url()
  } else if (from.value === 'file') {
    response = await ingest_file(await readFile(files.value[0]))
  }

  loading.value = false

  if (!response)
    return

  data.new_words = response.data.new_words.map((x) => { x.save = skip_pos.value.includes(x.tag) ? false : true; return x })
  data.unknown_words = response.data.unknown_words
  data.known_words = response.data.known_words
}

async function ingest_snippet() {
  try {
    var response = await store.lengualoo_api.post('/api/words/ingest-snippet/',
      {
        snippet: snippet.value,
        language: language.value,
      },
    )
  } catch (e) {
    console.log('Error:', e)

    return
  }

  return response
}

async function ingest_url() {
  try {
    var response = await store.lengualoo_api.post('/api/words/ingest-url/',
      {
        url: url.value,
        language: language.value,
      },
    )
  } catch (e) {
    console.log('Error:', e)

    return
  }

  return response
}

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    // Extract Base64 part after data URL header
    reader.onloadend = () => resolve(reader.result.split(',')[1])
    reader.readAsDataURL(blob)
    reader.onerror = error => reject(error)
  })
}

async function ingest_file(content) {
  const data = {
    'content': await blobToBase64(content),
    'language': language.value,
  }

  try {
    var response = await store.lengualoo_api.post('/api/words/ingest-file/', JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  } catch (e) {
    console.log('Error:', e)

    return
  }

  return response
}

async function import_words() {
  loading.value = true

  try {
    await store.lengualoo_api.post('/api/words/import/',
      {
        words: data.new_words.filter(x => { return x.save }),
        language: language.value,
        source: from.value === 'url' ? url.value : '',
      },
    )
  } catch (e) {
    console.log('Error:', e)

    return
  } finally {
    loading.value = false
  }

  dialog.value = false

  snippet.value = ''
  url.value = ''
  data.new_words = []
  data.unknown_words = []
  data.known_words = []

  emit('fetchWords')
}

defineExpose({
  dialog,
})
</script>

<style scoped>
.v-tab {
  text-transform: none;
}
</style>
