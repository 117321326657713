<template>
  <v-app>
    <v-app-bar color="primary" prominent :elevation="search ? 0 : undefined">
      <v-app-bar-nav-icon v-if="store.isAuthenticated && !hasContext()" @click.stop="drawer_click('drawer')"/>

      <v-btn v-if="store.isAuthenticated && hasSelectedWords()" icon="mdi-arrow-left" @click.stop="unselectWords()"/>
      <span v-if="store.isAuthenticated && hasSelectedWords()" class="text-h6">{{ view.selected_words.length }}</span>

      <v-btn v-if="store.isAuthenticated && !hasContext() && isWordsDefinitionsRoute()" @click="rotateLanguage">{{ activatedLanguages[getCurrentLanguageIndex()].name }}</v-btn>
      <v-toolbar-title v-if="!hasContext()"/>
      <v-spacer v-else-if="!hasSearch()"/>

      <v-btn v-if="store.isAuthenticated && hasSearch()" icon="mdi-arrow-left" @click.stop="store.search = ''; search = false"/>
      <v-text-field v-if="store.isAuthenticated && hasSearch()" v-model="store.search" ref="search_field" variant="plain" type="search" autocapitalize="none"/>
      <v-btn v-if="store.isAuthenticated && hasSearch(true)" icon="mdi-close" @click.stop="store.search = ''; search_activate()"/>
      <v-btn v-if="store.isAuthenticated && hasSearch(true)" @click.stop="store.align = (store.align + 1) % 3; search_activate()" :icon="store.aligns[store.align]['icon']"/>

      <v-btn v-if="store.isAuthenticated && !hasContext() && isWordsDefinitionsRoute()" icon="mdi-magnify" @click.stop="search_activate()"/>
      <v-btn v-if="store.isAuthenticated && !hasContext() && isWordsRoute()" icon="mdi-import" @click.stop="view.import_words.dialog = true"/>
      <v-btn v-if="store.isAuthenticated && !hasSelectedWords() && isWordsRoute()" icon="mdi-plus" @click.stop="view.add_word.dialog = true"/>
      <v-btn v-if="store.isAuthenticated && !hasSelectedWords() && isWordsDefinitionsRoute()" icon="mdi-dots-vertical" @click.stop="drawer_click('tools_drawer')" density="compact"/>

      <v-btn v-if="store.isAuthenticated && hasSelectedWords()" icon="mdi-check-all" @click.stop="selectAllWords()"/>
      <v-btn v-if="store.isAuthenticated && hasSelectedWords()" icon="mdi-delete" @click.stop="delete_dialog = true"/>
    </v-app-bar>

    <v-dialog v-model="delete_dialog" :width="mobile ? '' : '500'">
      <v-card>
        <v-card-title>
          Delete
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete the selected items? The action cannot be undone.
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="delete_dialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="deleteSelectedWords(); delete_dialog = false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer v-if="store.isAuthenticated" v-model="drawer" location="left" :permanent="!mobile">
      <v-list class="d-flex flex-column" height="100%">
        <v-list-item link to="/words">
          <v-list-item-title>Words</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/definitions">
          <v-list-item-title>Definitions</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/sources">
          <v-list-item-title>Sources</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/labels">
          <v-list-item-title>Labels</v-list-item-title>
        </v-list-item>

        <v-spacer/>

        <v-list-item link to="/profile" class="mt-auto">
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item>

        <v-divider/>

        <v-list-item link class="mt-auto">
          <v-list-item-title @click="store.logout">Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer v-if="store.isAuthenticated && isWordsDefinitionsRoute()" v-model="tools_drawer" location="right" :permanent="!mobile">
      <v-card class="pa-4" elevation="0">
        <v-select v-model="store.filters_parts_of_speech" variant="solo" menu-icon="" append-inner-icon="mdi-filter-variant" :items="store.parts_of_speech" multiple placeholder="Part of Speech" clearable/>

        <v-select v-model="store.filters_label" variant="solo" menu-icon="" append-inner-icon="mdi-label" :items="store.labels" placeholder="Label" clearable/>

        <v-select v-model="store.sorting" variant="solo" menu-icon="" prepend-inner-icon="mdi-sort" :items="sortings" item-title="name" item-value="id">
          <template #append-inner>
            <v-icon @click.stop="store.direction = (store.direction + 1) % 2">{{ store.directions[store.direction]['icon'] }}</v-icon>
          </template>
        </v-select>

        <v-switch v-if="$router.currentRoute.value.name == 'words'" v-model="store.with_definitions" color="primary" label="With definitions" density="compact"/>

        <v-switch v-if="$router.currentRoute.value.name == 'words'" v-model="store.show_definitions" color="primary" label="Show definitions" density="compact"/>
        <v-switch v-if="$router.currentRoute.value.name == 'definitions'" v-model="store.show_words" color="primary" label="Show words" density="compact"/>

        <v-select v-model="store.language" variant="solo" menu-icon="" append-inner-icon="mdi-book-education" :items="activatedLanguages" item-title="name" item-value="id"/>
      </v-card>
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <router-view v-slot="{ Component }">
          <component ref="view" :is="Component"/>
        </router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { computed } from 'vue'
import { ref } from 'vue'
import { nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'

import { appStore } from '@/store'

const store = appStore()
const { mobile } = useDisplay()
const router = useRouter()

const drawer = ref(!mobile.value)
const tools_drawer = ref(!mobile.value)
const delete_dialog = ref(false)
const search = ref(false)
const search_field = ref(null)

function search_activate() {
  search.value = true

  if (mobile.value) {
    drawer.value = false
    tools_drawer.value = false
  }

  nextTick(() => {
    setTimeout(() => {
      search_field.value.focus()
    })
  })
}

const activatedLanguages = computed(() => {
  if (store.activated_languages.length <= 1) {
    return store.activated_languages
  }

  return [{ id: '', name: 'All' }].concat(store.activated_languages)
})

function getCurrentLanguageIndex() {
  for (const [index, lang] of activatedLanguages.value.entries()) {
    if (lang.id === store.language) {
      return index
    }
  }
}

function rotateLanguage() {
  if (activatedLanguages.value.length <= 1) {
    return
  }

  const index = getCurrentLanguageIndex()
  store.language = activatedLanguages.value[(index + 1) % activatedLanguages.value.length].id
}

const sortings = [
  { id: 'created', name: 'Creation' },
  { id: 'alphabetic', name: 'Alphabetic' },
  { id: 'updated', name: 'Update' },
]

function drawer_click(source) {
  if (source === 'drawer') {
    if (!drawer.value && mobile.value) {
      tools_drawer.value = false
    }
    drawer.value = !drawer.value
  } else if (source === 'tools_drawer') {
    if (!tools_drawer.value) {
      if (mobile.value) {
        drawer.value = false
      }
    }
    tools_drawer.value = !tools_drawer.value
  } else {
    throw 'Wrong drawer source'
  }
}

const view = ref(null)

function hasSelectedWords() {
  if (!view.value)
    return false

  if (!view.value.selected_words)
    return false

  if (!view.value.selected_words.length)
    return false

  return true
}

function hasSearch(with_text=false) {
  if (!search.value)
    return false

  if (hasSelectedWords())
    return false

  if (with_text)
    if (!store.search)
      return false

  return true
}

function isWordsRoute() {
  return router.currentRoute.value.name === 'words'
}

function isWordsDefinitionsRoute() {
  return ['words', 'definitions', 'sources'].find(x => x === router.currentRoute.value.name)
}

function hasContext() {
  return hasSelectedWords() || hasSearch()
}

function unselectWords() {
  view.value.selected_words.splice(0)
}

function selectAllWords() {
  for (var word of view.value.all_words) {
    if (!view.value.selected_words.includes(word.uuid)) {
      view.value.selected_words.push(word.uuid)
    }
  }
}

async function deleteSelectedWords() {
  try {
    await store.lengualoo_api.post('/api/words/delete/', {
      uuids: view.value.selected_words,
    })
  } catch (e) {
    console.log('Error', e)
    return
  } finally {
    view.value.selected_words.splice(0)
  }

  // FIXME: delete words from words/remaining_words, instead of triggering the API call
  view.value.isLoading = true
  view.value.fetchWords(view.value.bucket)
}
</script>

<style scoped>
.v-container {
  padding: 0;
}
</style>

<style>
.v-chip {
  user-select: none;
}

pwa-camera-modal-instance {
  z-index: 10000 !important;
}
</style>
