<template>
  <div class="definitions" style="position: relative;">
    <v-progress-linear v-if="isLoading" indeterminate color="primary" absolute/>

    <v-card elevation="0">
      <v-card-title>
        Definitions
        <v-chip v-if="language != undefined && language" class="ml-2">{{ all_languages[language]['name'] }}</v-chip>
        <v-chip v-if="definitions.length !== 0" class="ml-2">{{ n_definitions }}</v-chip>
      </v-card-title>
    </v-card>

    <v-card v-if="error" elevation="0">
      <v-card-subtitle>An error occurred: {{ error }}</v-card-subtitle>
    </v-card>

    <div class="words-list" v-else>
      <v-list id="definitions-list">
        <div v-for="(definition, index) in definitions" :key="definition.uuid">
          <SwipeItem :id="`definition-${ definition.uuid }`" :uuid="definition.uuid"
                     :title="definition.text"
                     :subtitle="store.show_words ? definition.defines.text : ''"
                     :swipe_icon_left="swipe_icon_left" :swipe_icon_right="swipe_icon_right"
                     :labels="definition.defines.labels"
                     :part-of-speech="parts_of_speech[definition.defines.part_of_speech]"
                     @action="definitionAction"
          />
          <v-card v-if="index === definitions.length - 10" v-intersect="infiniteScrolling"/>
        </div>
      </v-list>
    </div>
  </div>

  <v-bottom-navigation app hide-on-scroll scroll-threshold="1" scroll-target="#definition-list">
    <v-btn @click="fetchDefinitions('unknown')" :active="bucket == 'unknown'"><v-icon>mdi-head-dots-horizontal</v-icon>Unkown</v-btn>
    <v-btn @click="fetchDefinitions('known')" :active="bucket == 'known'"><v-icon>mdi-lightbulb-on</v-icon>Known</v-btn>
  </v-bottom-navigation>
</template>

<script setup>
import { ref } from 'vue'
import { onMounted } from 'vue'

import SwipeItem from '../components/SwipeItem.vue'

import { appStore } from '@/store'
import { all_languages } from '@/store'
import { parts_of_speech } from '@/store'

const store = appStore()

const bucket_icons = {
  'unknown': 'mdi-head-dots-horizontal',
  'known': 'mdi-lightbulb-on',
}

const bucket_actions = {
  'unknown': {
    'left': '',
    'right': 'known',
  },
  'known': {
    'left': 'unknown',
    'right': '',
  },
}

const language = ref(store.language)
const remaining_definitions = ref([])
const definitions = ref([])
const n_definitions = ref(null)
const bucket = ref('')
const swipe_icon_left = ref('')
const swipe_icon_right = ref('')

const isLoading = ref(true)
const error = ref(null)

const sorting = ref('created')
const search = ref(null)
const align = ref(0)
const direction = ref(0)

const filters_parts_of_speech = ref(store.filters_parts_of_speech)
const filters_label = ref(store.filters_label)

store.$subscribe((mutation, state) => {
  if (sorting.value != state.sorting) {
    sorting.value = state.sorting
    fetchDefinitions(bucket.value)
  }

  if (search.value != state.search) {
    search.value = state.search
    fetchDefinitions(bucket.value)
  }

  if (align.value != state.align) {
    align.value = state.align
    fetchDefinitions(bucket.value)
  }

  if (direction.value != state.direction) {
    direction.value = state.direction
    fetchDefinitions(bucket.value)
  }

  if (language.value != state.language) {
    language.value = state.language
    fetchDefinitions(bucket.value)
  }

  if (JSON.stringify(filters_parts_of_speech.value.sort()) !== JSON.stringify(state.filters_parts_of_speech.sort())) {
    filters_parts_of_speech.value = state.filters_parts_of_speech.slice(0)
    fetchDefinitions(bucket.value)
  }

  if (filters_label.value != state.filters_label) {
    filters_label.value = state.filters_label
    fetchDefinitions(bucket.value)
  }
})

onMounted(() => {
  fetchDefinitions('unknown')
})

function definitionAction(uuid, action) {
  var _bucket = bucket_actions[bucket.value][action]  // FIXME
  if (!_bucket)
    return

  definitions.value.forEach(function(definition, index) {
    if(definition['uuid'] === uuid) {
      definitions.value.splice(index, 1)
      return
    }
  })

  moveDefinition(uuid, _bucket)
}

async function moveDefinition(uuid, action) {
  try {
    await store.lengualoo_api.post(`/api/words/${ uuid }/move/`, { bucket: action })
  } catch (e) {
    console.log('Error:', e)
  }
}

async function fetchDefinitions(where) {
  if (bucket.value != where) {
    n_definitions.value = null
    definitions.value.splice(0, definitions.value.length)

    isLoading.value = true
  }

  bucket.value = where
  swipe_icon_left.value = bucket_icons[bucket_actions[bucket.value]['right']]
  swipe_icon_right.value = bucket_icons[bucket_actions[bucket.value]['left']]

  if (language.value === undefined) {
    definitions.value = []
    return
  }

  try {
    const response = await store.lengualoo_api.get('/api/definitions/', {
      params: {
        bucket: bucket.value,
        sorting: store.sorting,
        search: store.search,
        align: store.aligns[store.align]['align'],
        direction: store.directions[store.direction]['direction'],
        language: store.language,
        parts_of_speech: store.filters_parts_of_speech,
        label: store.filters_label,
      },
    })

    remaining_definitions.value.splice(0)
    remaining_definitions.value.push(...response.data)
    definitions.value.splice(0)

    n_definitions.value = remaining_definitions.value.length

    pushDefinitions()

    error.value = null
  } catch (e) {
    error.value = e.message
  } finally {
    isLoading.value = false
  }
}

function pushDefinitions() {
  let n_definitions = 30

  definitions.value.push(...remaining_definitions.value.splice(0, n_definitions))
}

function infiniteScrolling(intersecting) {
  if (intersecting && remaining_definitions.value.length) {
    pushDefinitions()
  }
}
</script>
