import { createApp } from 'vue'
import { createVuetify } from 'vuetify'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import Vue3TouchEvents from 'vue3-touch-events'
import 'vuetify/styles'
import { fa } from 'vuetify/iconsets/fa'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { defineCustomElements } from '@ionic/pwa-elements/loader'

import router from './router'
import en from './locales/en'
import App from './App.vue'

const vuetify = createVuetify({
  components,
  directives,
  options: {
    customProperties: true
  },
  display: {
    mobileBreakpoint: 'sm',
  },
  icons: {
    defaultSet: 'mdi',
    iconfont: 'mdi',  // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    aliases,
    sets: {
      fa,
      mdi,
    },
  },
})
const i18n = createI18n({
  //locale: process.env.VUE_APP_I18N_LOCALE || navigator.language.substr(0, 2),
  //fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en },
  //messages: { en, fr },
})
const store = createPinia()
defineCustomElements(window)
const app = createApp(App)

app.use(i18n).use(router).use(store).use(vuetify).use(Vue3TouchEvents)
app.mount('#app')
