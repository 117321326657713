import { createRouter, createWebHistory } from 'vue-router'

import HomeView from './views/HomeView.vue'
import LabelsView from './views/LabelsView.vue'
import SourcesView from './views/SourcesView.vue'
import WordsView from './views/WordsView.vue'
import DefinitionsView from './views/DefinitionsView.vue'
import ProfileView from './views/ProfileView.vue'

import { appStore } from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/words',
    name: 'words',
    component: WordsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/definitions',
    name: 'definitions',
    component: DefinitionsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/labels',
    name: 'labels',
    component: LabelsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/sources',
    name: 'sources',
    component: SourcesView,
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to) => {
  const store = appStore()

  if (to.meta.requiresAuth && !store.isAuthenticated) {
    return {
      path: '/',
      query: { redirect: to.fullPath },
    }
  }

  if (store.isAuthenticated) {
    if (!store.language || !store.translation) {
      console.log('Preloading Profile')
      await store.loadProfile()
      await store.loadLabels()
    }

    if (to.path !== '/profile' && (!store.language || !store.translation)) {
      return {
        path: '/profile',
      }
    }
  }

  if (to.path === '/' && store.isAuthenticated) {
    return {
      path: '/words',
    }
  }
})

export default router
