<template>
  <v-dialog v-model="dialog" :activator="activator" :fullscreen="mobile" :hide-overlay="mobile" :width="mobile ? '' : '500'">
    <v-card density="compact">
      <v-toolbar color="primary">
        <v-btn @click="word_cancel" icon="mdi-arrow-left" density="compact"/>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer/>
        <v-btn v-if="word.text && !loading" @click="word_save" icon="mdi-check"/>
        <v-btn v-if="loading" class="pr-0">
          <v-progress-circular width="2" size="24" indeterminate/>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-card-subtitle class="pl-0 pb-2">Text</v-card-subtitle>

        <v-row>
          <v-col :cols="store.language ? '' : 9">
            <v-text-field v-model.trim="word.text" required :autofocus="mobile ? false : true" variant="solo" autocapitalize="none">
              <template #append-inner>
                <v-icon @click="add_definition">mdi-plus</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col v-if="!store.language">
            <v-select append-inner-icon="" v-model="word.language" variant="solo" :items="store.activated_languages" item-title="short_name" item-value="id"/>
          </v-col>
        </v-row>

        <v-card-subtitle class="pl-0 pb-2">Definitions</v-card-subtitle>

        <!-- FIXME: choose unique key -->
        <v-row v-for="(definition, index) in word.definitions" :key="index">
          <v-col cols="9">
            <v-text-field v-model.trim="definition.text" variant="solo" :append-inner-icon="word.definitions.length > 1 ? 'mdi-minus' : ''" @click:append-inner="remove_definition(index)" autocapitalize="none">
              <template #append-inner>
                <v-icon v-if="!definition.text && word.language && definition.language" @click="fetch_translation('google', word.language, definition.language).then((t) => { definition.text = t })">
                  mdi-google-translate
                </v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col>
            <v-select append-inner-icon="" v-model="definition.language" variant="solo" :items="store.activated_translations" item-title="short_name" item-value="id"/>
          </v-col>
        </v-row>

        <v-card-subtitle class="pl-0 pb-2">Part of Speech</v-card-subtitle>
        <v-select v-model="word.part_of_speech" variant="solo" :items="store.parts_of_speech"/>

        <v-card-subtitle v-if="!word.inflections.length" class="pl-0 pb-2">Base grammatical word</v-card-subtitle>
        <v-combobox v-if="!word.inflections.length" v-model="word.inflects" v-model:search="search" :loading="searching" variant="solo" :items="filtered_words" :no-filter="true" item-title="text" item-value="uuid" return-object autocapitalize="none" placeholder="Search"/>

        <v-card-subtitle v-if="word.inflections.length" class="pl-0 pb-2">Derived grammatical words</v-card-subtitle>
        <v-text-field v-for="inflection in word.inflections" :key="inflection.uuid" cols="5" v-model.trim="inflection.text" variant="solo" readonly autocapitalize="none"/>

        <v-card-subtitle class="pl-0 pb-2">Labels</v-card-subtitle>
        <v-select v-model="word.labels" variant="solo" :items="store.labels" multiple autocapitalize="none"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { onMounted } from 'vue'
import { reactive } from 'vue'
import { ref } from 'vue'
import { watch } from 'vue'
import { useDisplay } from 'vuetify'
import axios from 'axios'
import { Capacitor } from '@capacitor/core'

import { appStore } from '@/store'

const props = defineProps({
  activator: { type: String, default: '' },
  dialog: { type: Boolean },
  title: { type: String, default: '' },
  word: { type: Object, default: () => {} },
  words: { type: Array, default: () => [] },
})

const dialog = ref(props.dialog)
const loading = ref(false)
const searching = ref(false)
const search = ref(null)
const word = reactive({
  uuid: props.word.uuid,
  text: props.word.text,
  part_of_speech: props.word.part_of_speech,
  language: props.word.language,
  definitions: JSON.parse(JSON.stringify(props.word.definitions || [])),
  inflects: JSON.parse(JSON.stringify(props.word.inflects || { text: '', uuid: null })),
  inflections: JSON.parse(JSON.stringify(props.word.inflections || [])),
  labels: JSON.parse(JSON.stringify(props.word.labels || [])),
})

if (word.definitions.length == 0) {
  word.definitions.push({ text: '' })
}

const filtered_words = ref([])

const emit = defineEmits(['fetch-words'])

const { mobile } = useDisplay()
const store = appStore()

store.$subscribe((mutation, state) => {
  update_defaults(state)
})

onMounted(() => {
  update_defaults(store)
})

watch(search, (val) => {
  querySelections(val, word.text)
})

watch(dialog, (val) => {
  if (val) {
    document.title = 'dialog'
  } else {
    document.title = 'Lengualoo'
  }
})

function querySelections(v, text) {
  if (!v) {
    filtered_words.value = []
    return
  }

  searching.value = true

  let sub = (v || '').toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '')

  filtered_words.value = props.words.filter(w => {
    if (w.inflects && w.inflects.uuid)
      return false

    if (w.text === text)
      return false

    let base = (w.text || '').toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '')

    return base.indexOf(sub) > -1
  })

  filtered_words.value.sort((x, y) => x.text.localeCompare(y.text))

  searching.value = false
}

function update_defaults(state) {
  if (!word.uuid) {
    word.text = state.search
    word.language = state.language || state.profile.target_language
  }

  word.definitions.forEach(function(definition) {
    if (!definition.uuid) {
      definition.language = state.profile.target_translation
    }
  })
}

function add_definition() {
  word.definitions.unshift({ text: '', language: store.profile.target_translation })
}

function remove_definition(key) {
  word.definitions.splice(key, 1)
}

function word_cancel() {
  search.value = ''

  word.text = props.word.text
  word.part_of_speech = props.word.part_of_speech
  word.definitions = JSON.parse(JSON.stringify(props.word.definitions || []))
  word.inflects = JSON.parse(JSON.stringify(props.word.inflects || { text: '', uuid: null }))
  word.inflections = JSON.parse(JSON.stringify(props.word.inflections || []))
  word.labels = JSON.parse(JSON.stringify(props.word.labels || []))

  if (word.definitions.length == 0) {
    word.definitions.push({ text: '' })
  }

  update_defaults(store)

  dialog.value = false
}

async function word_save() {
  loading.value = true

  let inflects = null
  if (typeof word.inflects === 'string') {
    inflects = { uuid: null, text: word.inflects }
  }

  try {
    if (!word.uuid) {
      await store.lengualoo_api.post('/api/words/', {
        text: word.text,
        part_of_speech: word.part_of_speech,
        language: word.language,
        definitions: word.definitions.filter(d => d.text),
        inflections: word.inflections,
        inflects: word.inflects.text ? word.inflects : inflects,
        labels: word.labels,
      })
    } else {
      await store.lengualoo_api.put(`/api/words/${ word.uuid }/`, {
        text: word.text,
        part_of_speech: word.part_of_speech,
        language: word.language,
        definitions: word.definitions.filter(d => d.text),
        inflections: word.inflections,
        inflects: word.inflects.text ? word.inflects : inflects,
        labels: word.labels,
      })
    }
  } catch (e) {
    console.log(e)

    return
  } finally {
    loading.value = false
  }

  if (!word.uuid) {
    word.text = ''
    word.part_of_speech = '',
    word.definitions = [{ text: ''}]
    word.inflects =  { text: '', uuid: null }
    word.inflections = []
    word.labels = []

    update_defaults(store)
  }

  dialog.value = false

  emit('fetch-words')
}

async function fetch_translation_google(txt, from, to) {
  const api = axios.create({
    headers: Capacitor.isNativePlatform() ? {
      'user-agent': 'Mozilla/5.0',
    } : {},
  })

  const source = from
  const target = to
  const text = encodeURI(txt)
  const base_url = 'https://translate.googleapis.com/translate_a/single?client=gtx'
  const url = `${base_url}&sl=${source}&tl=${target}&dt=t&q=${text}`

  try  {
    var response = await api.get(url)
  } catch (e) {
    console.log(e)

    return
  }

  try {
    var translation = response.data[0][0][0]
  } catch (e) {
    console.log(e)

    return
  }

  return translation
}

async function fetch_translation(provider, from, to) {
  if (!word.text) {
    return
  }

  if (provider == 'google') {
    return await fetch_translation_google(word.text, from, to)
  }
}

defineExpose({
  dialog,
  word,
})
</script>
